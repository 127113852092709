import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "./boton/ButtonContent";

function BloqueHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="relative bg-header2 md:pt-[250px] pt-[200px] pb-[50px] lg:pb-[200px] md:w-full w-full mx-auto flex md:flex-row flex-col p-6">
        <div className="md:w-4/5 w-full mx-auto flex md:flex-row flex-col p-6 lg:pb-[160px]">
          <div className="md:w-[60%] w-full md:text-start text-center pb-10">
            <h1 className=" md:text-start text-[62px] font-extrabold text-center">
              {rpdata?.dbSlogan?.[4].slogan}
            </h1>
            <p className="md:text-start text-center">
              {rpdata?.dbHome?.[2].text}
            </p>
            <ButtonContent btnStyle="three" />
          </div>
          <div className="md:w-[40%] w-full h-full p-4 flex items-center">
            <div className=" md:w-full md:h-[800px] w-full md:mt-[-270px] md:mb-[-220px] md:ml-[300px]">
              <div className="w-full h-full flex justify-center align-middle items-center">
                <img
                  src={rpdata?.gallery?.[19]}
                  alt="Not Found"
                  className="mx-auto rounded-full md:w-[450px] md:h-[450px] sm:w-[600px] sm:h-[600px] w-[350px] h-[300px]  object-cover md:mr-[500px] border-white shadow-xl border-[7px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BloqueHome;

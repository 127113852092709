import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

function Directories() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#00A940" fill-opacity="1" d="M0,320L80,314.7C160,309,320,299,480,256C640,213,800,139,960,106.7C1120,75,1280,85,1360,90.7L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>

      <div>
        <div className="px-4 ">
          <div className="flex justify-center items-center  -pb-[300px]">
            {
              rpdata?.gmb?.link.length > 1 ?
                <a href={rpdata?.gmb?.link} target="_blank" rel='noopener noreferrer'>
                  <img src={rpdata?.gmb?.img} alt="Not found" width={'250px'} />
                </a>
                : null
            }
          </div>
          {
            rpdata?.dbSocialMedia?.redes.length > 0 || rpdata?.dbSocialMedia?.directorios.length > 0 ?
              <h2 className="text-center pb-5 ">
                Find Us On
              </h2>
              : null
          }
          {/* <div className={`grid ${rpdata?.dbSocialMedia?.directorios?.length > 3 || rpdata?.dbSocialMedia?.redes?.length > 3 ? 'md:grid-cols-4' : 'md:grid-flow-col' } grid-cols-1 gap-4 bg-auto md:bg-contain bg-top-[100px]`}> */}
          <div className={`w-4/5 mx-auto flex flex-wrap justify-center items-center gap-4 pb-5`}>
            {
              rpdata?.dbSocialMedia?.directorios.length > 0 ?
                rpdata?.dbSocialMedia?.directorios.map((item, index) => {
                  return (
                    <div
                      className="text-center content-directorios"
                      key={index}
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
            {
              rpdata?.dbSocialMedia?.redes.length > 0 ?
                rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <div
                      className="text-center content-directorios"
                      key={index}
                    >
                      <a href={item.url} target="_blank" rel='noopener noreferrer'>
                        <img src={item.image} alt="Not Found" width={'250px'} />
                      </a>
                    </div>
                  );
                }) : null
            }
          </div>
        </div>
      </div>
    </>

  );
}

export default Directories;
